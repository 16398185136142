import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Color from 'color';
import { ProductRoutingService } from './product-routing.service';
import { NotificationService } from './notification.service';
import { Logger } from './common/logger.service';
import { BrandThemeColors, Tenant } from '@parashift/shared/models';
import { ErrorResponse } from './airbrush/base';
import { CurrentTenantRegister } from './registers/current-tenant.register';

export interface BrandThemeSettings {
  primaryColor: string;
  secondaryColor: string;
  logoUrl: string;
}

export const PRIMARY_COLOR = '#4db6ce';
export const SECONDARY_COLOR = '#095769';

@Injectable({
  providedIn: 'root'
})
export class WhitelabellingService {
  private unsubscribeCollector = new Subject<void>();
  private _brandThemeSettings: BehaviorSubject<BrandThemeSettings | Partial<BrandThemeSettings>> = new BehaviorSubject({});
  private _brandThemeColors: BehaviorSubject<BrandThemeColors | Partial<BrandThemeColors>> = new BehaviorSubject({});
  private brandThemeColors$ = this._brandThemeColors.asObservable();
  private tenant: Tenant;

  brandThemeSettings$ = this._brandThemeSettings.asObservable();

  constructor(
    private currentTenantRegister: CurrentTenantRegister,
    private productRoutingService: ProductRoutingService,
    private logger: Logger,
    private notificationService: NotificationService,
  ) {}

  init() {
    this.initColorChangeListener();
    this.initCurrentTenantChangeListener();
    this.initProductChangeListener();
  }

  getDefaultTheme(): BrandThemeSettings {
    return {
      primaryColor: '#4db6ce',
      secondaryColor: '#095769',
      logoUrl: ''
    };
  }

  setBrandThemeColors(brandThemeColors: BrandThemeColors) {
    this.tenant.brand_theme = brandThemeColors;
    this.applyThemeSettings();
    this.applyColorCss();
    this._brandThemeColors.next(brandThemeColors);
  }

  resetBrandTheme() {
    this.tenant.logo_attachment?.id && this.tenant.logo_attachment?.url && this.tenant.logo_attachment.destroy();
    this.tenant.brand_theme = {
      primaryColor: PRIMARY_COLOR,
      secondaryColor: SECONDARY_COLOR
    }
    this.applyThemeSettings();
    this.applyColorCss();
    this.saveBrandTheme();
  }

  destroyListener() {
    this.unsubscribeCollector.next();
    this.unsubscribeCollector.complete();
  }

  private initColorChangeListener() {
    this.brandThemeColors$
      .pipe(
        debounceTime(200),
        takeUntil(this.unsubscribeCollector)
      ).subscribe(() => this.saveBrandTheme());
  }

  private initCurrentTenantChangeListener() {
    this.currentTenantRegister.tenant$
    .pipe(takeUntil(this.unsubscribeCollector))
    .subscribe((tenant) => {
      if (!tenant) {
        return;
      }

      this.tenant = tenant;
      this.applyThemeSettings();
      this.applyColorCss();
    });
  }

  private initProductChangeListener() {
    this.productRoutingService.product$
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribeCollector)
    )
    .subscribe(product => {
      if (product === 'mdt') {
        this.setDefaultTheme();
      } else if (product === 'pdc') {
        this.applyThemeSettings();
        this.applyColorCss();
      }
    });
  }

  private setDefaultTheme() {
    this._brandThemeSettings.next(this.getDefaultTheme());
    this.applyColorCss();
  }

  private applyThemeSettings() {
    const { brand_theme } = this.tenant;
    const brandThemeSettings = (Object.keys(brand_theme).length > 0 ? brand_theme : this.getDefaultTheme()) as BrandThemeSettings;
    brandThemeSettings.logoUrl = this.tenant.logo_attachment && this.tenant.logo_attachment.relationMethod !== 'destroy' && this.tenant.logo_attachment.url ? this.tenant.logo_attachment.url : '';
    this._brandThemeSettings.next(brandThemeSettings);
  }

  private applyColorCss() {
    const brandThemeSettings = this._brandThemeSettings.value;
    const color = Color(brandThemeSettings.primaryColor);

    const hoverColor = color.desaturate(0.1).lighten(0.1).hex();
    const hoverBorderColor = color.desaturate(0.1).lighten(0.2).hex();
    const sidebarBg = color.desaturate(0.2).darken(0.3).hex();
    const userMenuBg = color.luminosity() > 0.5 ? color.desaturate(0.4).darken(0.2).hex() : color.desaturate(0.4).hex();
    const activeNavColor = color.luminosity() > 0.5 ? color.blacken(0.2).darken(0.4).hex() : Color('white').mix(color, 0.2).hex();
    const navColor = Color('white').mix(color, 0.4).hex();
    const navTitleColor = color.darken(0.15).hex();
    const userMenuNavColor = color.luminosity() < 0.5 ? color.darken(0.7).hex() : color.lighten(0.2).hex();

    document.documentElement.style.setProperty('--primary-color', brandThemeSettings.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', brandThemeSettings.secondaryColor);
    document.documentElement.style.setProperty('--sidebar-bg', sidebarBg);
    document.documentElement.style.setProperty('--hover-color', hoverColor);
    document.documentElement.style.setProperty('--hover-border-color', hoverBorderColor);
    document.documentElement.style.setProperty('--user-menu-bg', userMenuBg);
    document.documentElement.style.setProperty('--nav-color', navColor);
    document.documentElement.style.setProperty('--nav-title-color', navTitleColor);
    document.documentElement.style.setProperty('--active-nav-color', activeNavColor);
    document.documentElement.style.setProperty('--user-menu-nav-color', userMenuNavColor);
  }

  private saveBrandTheme() {
    if (!this.tenant) {
      return;
    }

    this.currentTenantRegister.saveAndSetTenant(this.tenant).subscribe({
      next: () => {
        this.notificationService.success({
          description: $localize `:@@shared.components.tenant_settings.brand_theme.save_success:The brand theme has been successfully saved.`,
          title: $localize `:@@shared.components.tenant_settings.brand_theme.saved:Brand theme saved`
        });
      },
      error: (error: ErrorResponse) => {
        this.logger.log('Save Brand Theme Error', error);
        this.notificationService.formattedErrorResponse(error);
      }
    });
  }
}
